@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-blue);
}

.App {
  text-align: center;
}

/*-------------------------------------------*\
    Base
\*-------------------------------------------*/
.container.in-container--responsive {
  min-height: calc(100vh - 392px);
}

/*-------------------------------------------*\
    Search bar
\*-------------------------------------------*/
.ant-cascader-menu-item {
  max-width: 222px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.in-sectors-cascader .ant-cascader-menu {
  height: 100%;
}

/*-------------------------------------------*\
    Multi-step registration
\*-------------------------------------------*/
.in-ms-registration .ant-input {
  font-size: 1rem;
}

.ant-radio-group.in-registration-radio-card {
  width: 100%;
  display: flex;
}

.ant-radio-group-large
  .in-registration-radio-card-button.ant-radio-button-wrapper {
  margin: 0 5px 30px 0;
  text-align: center;
  height: 112px;
  line-height: 18px;
  font-weight: 600;
  display: flex;
  flex: 1 1;
  /* width: 0; */
  align-items: center;
  justify-content: center;
}

.in-registration-radio-card-button.ant-radio-button-wrapper span {
  font-size: 12px;
}

.in-registration-radio-card-button.ant-radio-button-wrapper:first-child,
.in-registration-radio-card-button.ant-radio-button-wrapper:last-child {
  border-radius: 0px;
}

.ant-radio-group-large
  .in-registration-radio-card-button.ant-radio-button-wrapper
  .anticon.in-registration-radio-card-icon {
  display: block;
  font-size: 35px;
  padding: 0 12px 12px 12px;
}

.in-destination-choices {
  display: inline-flex;
}

.in-destination-choices span {
  font-size: 0.9em;
}

/* .in-tooltip-inner--sm .ant-tooltip-inner {
  font-size: 12px;
} */

.in-registration-radio-tag.ant-radio-group {
  display: flex;
  justify-content: space-between;
}

.in-registration-radio-tag-button.ant-radio-button-wrapper:not(:first-child)::before {
  content: none;
}

.in-registration-radio-tag-button.ant-radio-button-wrapper:first-child,
.in-registration-radio-tag-button.ant-radio-button-wrapper:last-child,
.in-registration-radio-tag-button.ant-radio-button-wrapper {
  border-radius: 8px;
  border-left-width: 1px;
  line-height: 26px;
  height: 28px;
}

.in-registration-radio-tag-button.ant-radio-button-wrapper span {
  font-size: 13px;
}

:root{--blue-light: #f7f9fc;--white: #ffff;--primary-blue: #1d2b56;--primary-color: #df3848;--secondary-bleu: #a5aabb;--secondary-light: #e9eaf2}h1,h2,h3,h4,h5,h6{color:#1d2b56;color:var(--primary-blue)}.ant-select-selector{font-size:1rem}.avatar-uploader .ant-upload{width:160px !important;height:158px !important}

