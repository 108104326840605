@import 'variables';

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-blue);
}
.ant-select-selector {
  font-size: 1rem;
}
.avatar-uploader .ant-upload {
  width: 160px !important;
  height: 158px !important;
}
